(function ($) {
	"use strict";

	console.log("Hello, World!");

	AOS.init();

	$(window).on("scroll", function () {
		// Back Top Button
		if ($(window).scrollTop() > 700) {
			$(".return-to-top").addClass("back-top");
		} else {
			$(".return-to-top").removeClass("back-top");
		}

		// Sticky Menu
		if ($("header").hasClass("sticky-on")) {
			var stickyPlaceHolder = $("#sticky-placeholder"),
				menu = $("#navbar-wrap"),
				menuH = menu.outerHeight(),
				topbarH = $("#topbar-wrap").outerHeight() || 0,
				targrtScroll = topbarH,
				header = $("header");
			if ($(window).scrollTop() > targrtScroll) {
				header.addClass("sticky");
				stickyPlaceHolder.height(menuH);
			} else {
				header.removeClass("sticky");
				stickyPlaceHolder.height(0);
			}
		}
	});

	$(".hamburger").click(function(){
		$(this).toggleClass("active");
		$('.sidemenu-wrapper').toggleClass('show');
	});

	$('.close_btn, .sidemenu-wrapper').on('click', function () {
		$('.hamburger').removeClass('active');
		$('.sidemenu-wrapper').removeClass('show');
	});

	$('.test-carousel').owlCarousel({
		loop: true,
		margin:23,
		responsiveClass:true,
		nav: false,
		responsive:{
			0:{
				items:1,
			},
			800:{
				items:1,
			},
			1200:{
				items:2,
			},
			1400:{
				items:3,
			}
		}
	});

})(jQuery);
